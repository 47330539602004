.menu-categorias-principal{
	background-color:$theme;
	color:#FFF;
	font-family: 'montserratbold';

	.btn{
		color:#FFF;
		border-radius:0;
		box-shadow:none;
		border-left:none;
		border-bottom:1px solid #FFF;
	}

	ul a,
	ol a{
		position:relative;

		&:before{
			content:'-';
			display:inline-block;
			margin-right:10px;
		}

	}

	.fas{
	}

	&,
	ul,
	ol{
		padding-left:0;
		list-style-type:none;
		width:100%;
		display:none;
	}

	@include media-breakpoint-down(md){
		width:100%;
		position:relative;
		display:block;
		margin-bottom:20px;

		&:after{
			content:"ALGUMAS CATEGORIAS:";
			display:block;
			padding-top:20px;
			text-align:center;
			font-size:16px;
		}

	}

	@include media-breakpoint-up(lg){
		position:fixed;
		top:0;
		left:-250px;
		width:250px;
		height:100% !important;
		display:block !important;
		transition:left 0.6s linear;
		border-right:1px solid darken($theme,10%);
		overflow:auto;
		z-index:1000;
	}

	a,
	.btn-toggle{
		border-bottom:1px solid #FFF;
	}

	li{
		display:block;

		a{
			display:block;
			padding:10px 15px;

			@include hover-focus{
				background-color:darken($theme,5%);
				color:#FFF;
			}

		}

	}

	.item{
		display:flex;
		flex-wrap:wrap;

		a{
			width:80%;
			flex-grow:1;
		}

		.btn[aria-expanded="true"]{

			& ~ ul{
				display:block !important;
			}

		}

	}

	.subcategoria{
		background-color:$theme;

		a{

			@include hover-focus{
				background-color:darken(lighten($theme,10%),5%);
			}

		}

	}

	.sub-subcategoria{
		background-color:$theme;

		a{

			@include hover-focus{
				background-color:darken(lighten($theme,20%),5%);
			}

		}

	}

	@include media-breakpoint-down(md){
		width:100%;
		margin-bottom:20px;
	}

}