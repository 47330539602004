.media-custom{

	@include media-breakpoint-up(sm){
		display:flex;
		flex-wrap:wrap;
		align-items:center;
	}

	.media-custom__icon{

		.fas{
			font-size:19px;
		}

		@include media-breakpoint-up(sm){
			padding-right:8px;
		}

	}

}