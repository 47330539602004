.redes-sociais{

	& > *{
		font-size:20px;
		width:(36 / 20) * 1em;
		height:(36 / 20) * 1em;
		line-height:(36 / 20) * 1em;
		text-align:center;
		background-color:#FFF;
		color:$theme;
		border-radius:100%;
		@include margin(0,6);

		@include hover-focus{
			background-color:darken($theme,10%);
		}

	}

}