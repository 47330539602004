.banner{

	.carousel-item{
		margin-bottom:0;
	}

}

.vantagens{
	background-color:darken($color: #0af9ef, $amount: 15%) ;
	padding-top:40px;
	padding-bottom:20px;

	@include media-breakpoint-down(md){
		text-align:center;
	}

}

.produtos{
	padding-top:58px;
	padding-bottom:24px;
}

.segura-carousel{
	position:relative;

	.slick-slider{
		z-index:200;
	}

	.segura-controls{
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		display:flex;
		flex-wrap:wrap;
		justify-content:center;
		align-items:center;

		.clearfix{
			width:100%;
			padding-bottom:90px;

			& > *{
				font-size:38px;
				width:1em;
				height:1em;
				display:inline-flex;
				align-items:center;
				flex-wrap:wrap;
				justify-content:center;
				background-color:#EEE;
				text-align:center;
				border-radius:100%;
				position:relative;
				z-index:600;

				&.float-left{
					left:-40px;
				}

				&.float-right{
					transform:rotate(180deg);
					right:-40px;
				}

			}

		}

	}

}

.ofertas{
	padding-bottom:20px;
}

.outras-ofertas{
	padding-bottom:54px;
	padding-top:24px;
}
