.card-vantagens{
	z-index:6;
	position:relative;
	margin-bottom:10px;

	@include media-breakpoint-up(sm){
		display:flex;
		flex-wrap:wrap;
		align-items:center;
	}

	figure.card-vantagens__figure{
		font-size:54px;
		width:1em;
		height:1em;
		border-radius:100%;
		border:3px solid #FFF;
		display:flex;
		flex-wrap:wrap;
		align-items:center;
		justify-content:center;
		position:relative;
		max-width:54px;

		@include media-breakpoint-only(xs){
			margin-left:auto;
			margin-right:auto;
		}

		@include media-breakpoint-up(sm){
			margin-right:16px;
		}

		&:before{
			content:"";
			display:block;
			border-radius:inherit;
			background-color:$theme;
			position:absolute;
			z-index:-1;
			top:-10px;
			left:3px;
			width:calc(100% + 8px);
			height:calc(100% + 8px);
		}

	}

	.card-vantagens__content{
		color:#FFF;

		@include media-breakpoint-up(sm){
			max-width:180px;
		}

		.card-vantagens__title{
			font-size:14px;
			font-family: 'montserratextrabold';
			margin-bottom:2px;
		}

		.card-vantagens__desc{
			font-size:13px;
			line-height:1.2;
		}

	}

}