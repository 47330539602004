.mbl-controls{
	display:flex;
	flex-wrap:wrap;
	align-items:center;
	justify-content:space-between;
	background-color:#FFF;

	&,
	.d-flex > .btn{
		color:$theme;
	}

	figure{
		margin-bottom:0;
		padding:5px 15px;
		max-width:90px;
	}

	.d-flex{
		font-size:24px;

		a,
		button{
			box-shadow:none;
		}

	}

	.dropdown-toggle{
		font-size:20px;
		padding-top:0;
		padding-bottom:0;
		position:relative;

		&::after{
			display:none !important;
		}

	}

	.dropdown-menu{
		top:calc(100% + 10px) !important;
		left:-80px;
	}

}