.card-produtos{
	margin-bottom:30px;
	max-width:256px;
	margin-left:auto;
	text-align:center;
	margin-right:auto;

	.card-produtos__figure{
		background-color:#EEE;
		min-height:294px;
		display:flex;
		flex-wrap:wrap;
		align-items:center;
		justify-content:center;
		min-width:256px;
		margin-bottom:12px;
	}

	.card-produtos__content{
		border-bottom:5px solid #EEE;
		margin-bottom:11px;

		.card-produtos__desc{
			margin-bottom:6px;
		}

		.card-produtos__title{
			font-size:20px;
			font-family: 'montserratbold';
			line-height:1;
			margin-bottom:4px;
		}

		.card-produtos__gray{
			color:#999;
			font-size:12px;
			margin-bottom:8px;
			line-height:1.3;
		}

	}

	.card-produtos__footer{

		.btn{
			font-size:13px;
			font-family: 'montserratbold';
			border-radius:0;
			padding:13px 15px;
		}

	}

}