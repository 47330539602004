.form-buscas{

	@include media-breakpoint-down(md){
		margin-bottom:20px;
		padding-left:15px;
		padding-right:15px;
	}

	form{

		.form-group,
		.input-group{

			label{

				@include media-breakpoint-up(lg){
					@include sr-only();
				}

			}

			.form-control{
				font-size:12px;
				height:100%;
				min-height:46px;
				padding-left:14px;
				@include place(#000);
				box-shadow:none;

				&:focus{
					border-color:$theme;
				}

			}

			.input-group-prepend{

				.btn{
					min-width:54px;
					padding:0;
					display:flex;
					flex-wrap:wrap;
					align-items:center;
					justify-content:center;
				}

			}

		}

	}

}

select.form-control{
	background-image:url("../images/bg-select-custom.png");
	background-repeat:no-repeat;
	background-position:calc(100% - 14px) center;
	-webkit-appearance:none;
}

.form-newsletter{
	max-width:418px;
	margin-right:auto;

	@include media-breakpoint-down(md){
		margin-left:auto;
	}

	form{

		.input-group,
		.form-group{
			font-family: 'montserratbold';

			.form-control{
				@include place(#BBBBBB);
				border-color:#FFF;
				font-size:13px;
				height:100%;
				min-height:48px;
				border-radius:30px 0 0 30px;
			}

			.input-group-prepend{

				.btn{
					font-size:12px;
					border-radius:0 30px 30px 0;
				}

			}

		}

	}

}

.form-internas{

	form{

		.input-group,
		.form-group{
			margin-bottom:24px;

			.form-control{
				font-size:14px;
				@include place(#000);
				font-family: 'montserratbold';
				min-height:56px;
				@include sombra(#EEEEEE);
				resize:none;
				border-radius:0;
				padding-left:16px;
				background-color:#EEEEEE;
				border-color:#EEEEEE;
			}

		}

	}

}

.form-internas-diferente{

	form{

		.input-group,
		.form-group{
			margin-bottom:20px;

			label{
				color:#656565;
				font-size:14px;
			}

			.form-control{
				font-size:13px;
				@include place(#000);
				min-height:46px;
				@include sombra(#EEEEEE);
				border-radius:0;
				resize:none;
				padding-left:12px;
				background-color:#EEEEEE;
				border-color:#EEEEEE;
			}

		}

	}

}

.form-internas-text-gray{

	form{

		.input-group,
		.form-group{
			margin-bottom:24px;

			.form-control{
				font-size:14px;
				@include place(#555);
				font-family: 'montserratbold';
				min-height:56px;
				@include sombra(#EEEEEE);
				resize:none;
				border-radius:0;
				padding-left:16px;
				background-color:#EEEEEE;
				border-color:#EEEEEE;
			}

		}

	}

}