.menu-principal{
	list-style-type:none;
	margin-bottom:0;
	padding-left:0;
	font-size:12px;

	@include media-breakpoint-up(lg){
		display:flex;
		flex-wrap:wrap;
		max-width:402px;
	}

	li{

		& + li{

			@include media-breakpoint-up(lg){

				&:before{
					content:"";
					display:inline-block;
					font-size:4px;
					width:1em;
					height:1em;
					border-radius:100%;
					background-color:$theme;
					margin: 0 8px;
					vertical-align:middle;
					transform:translateY(-50%);
				}

			}

		}

		a{

			@include media-breakpoint-down(md){
				display:block;
				padding:10px 15px;
				border-top:1px solid transparent;
				border-bottom:1px solid transparent;
			}

		}

		&.active > a,
		&:hover  > a{
			
			@include media-breakpoint-down(md){
				background-color:$theme;
				color:#FFF;
				border-color:darken($theme,10%);
			}

		}

	}

}