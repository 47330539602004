.rodape{

	@include media-breakpoint-down(md){
		text-align:center;
	}

	.rodape-principal{
		background-color:#E6E6E6;
		padding-top:40px;
		padding-bottom:30px;
		background-repeat:no-repeat;
		background-size:cover;
		background-attachment:fixed;

		*:not(.font--mnt-bld){
			font-weight:bold;
		}

		.container > .row > *{
			margin-bottom:40px;
		}

		.rodape-assist,
		.rodape-title{
			color:$theme;
			font-family: 'montserratblack';
			line-height:1;
		}

		.rodape-title{
			font-size:15px;
			margin-bottom:12px;
		}

		.rodape-assist{
			font-size:50px;
			margin-bottom:28px;

			@include media-breakpoint-down(md){
				font-size:32px;
			}

		}

		.menu-rodape{
			list-style-type:none;
			padding-left:0;
			margin-bottom:46px;
			font-family: 'montserratbold';
			padding-top:3px;
			font-size:13px;

			li{
				margin-bottom:25px;

				&:last-child{
					margin-bottom:0;
				}

			}

		}

	}

	.rodape-copyright{
		background-color:$theme;
		color:#FFF;
		padding-top:28px;
		padding-bottom:14px;

		.container{
			position:relative;

			.gv8{

				img{
					max-width:39px;
				}

			}

		}

	}

}