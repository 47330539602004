.header-section{

	.header-section__title{
		font-size:40px;
		color:#000;
		font-family: 'montserratbold';
		line-height:1;
		margin-bottom:10px;

		@include media-breakpoint-down(md){
			font-size:28px;
		}

	}

}