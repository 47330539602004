.menu-categorias-topo{
	list-style-type:none;
	margin-bottom:0;
	padding-left:0;

	li{

		a{

			@include media-breakpoint-down(md){
				padding:12px 15px;
				display:block;
				border-top:1px solid transparent;
				border-bottom:1px solid transparent;
			}

		}

		&.active > a,
		&:hover  > a{

			@include media-breakpoint-down(md){
				background-color:$theme;
				color:#FFF;
				border-color:darken($theme,10%);
			}

		}

		&.dropdown{

			.dropdown-menu{
				margin:0;
				background-color:$theme;
				color:#FFF;
				border:none;
				border-top:2px solid darken($theme,10%);
				border-radius:0;
				padding:5px 10px;
				width:100%;
				font-size:14px;

				@include media-breakpoint-down(md){
					display:block;
					width:100%;
					position:relative;
					padding:0;
					background-color:darken($theme,4%);
					margin-bottom:15px;
				}

				li{

					a{
						display:block;
						padding:5px 10px;
						word-break:normal;
					}

					&:hover{

						a{
							color:#000;
						}

					}

				}

			}

			&:hover{

				.dropdown-menu{
					display:block;
				}

			}

			&:hover .dropdown-menu,
			.dropdown-menu:hover{
				display:block;
			}

		}

	}

}
