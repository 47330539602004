.input-file-custom{

	label.form-control{
		display:flex;
		flex-wrap:wrap;
		align-items:center;

		.output{
			word-break:break-all;
		}

	}

	input{
		position:fixed;
		left:-200vw;
		top:0;
	}

}