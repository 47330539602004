@import "_fontfaces";

body{
	font-size:13px;
	color:#000;
	font-family: 'montserratregular';
}

.bg-menu{
	display:none;
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background-color:rgba(#000,0.5);
	z-index:800;
}

#app{
	position:relative;
	overflow:hidden;
}

.text--black{
	color:#000 !important;
}

.text--263351{
	color:#263351 !important;
}

.text--555{
	color:#555 !important;
}

.text--999{
	color:#999 !important;
}

.topo a,
.rodape a{
	word-break:break-all;
}

.logo{
	margin-bottom:0;
}

.internas img{
	@include img-fluid();
}

a,
.btn{
	transition:all 0.6s linear;
}

a{
	color:inherit;
	text-decoration:none;

	@include hover-focus{
		color:inherit;
		text-decoration:none;
	}

}

*[data-animate]{
	opacity:0;
}

.animated{
	animation-direction:1.4s;
	animation-fill-mode: both;
}

.animated[data-animate]{
	opacity:1;
}

.atraso-1{
	animation-delay:0;
}

.atraso-2{
	animation-delay:0.2s;
}

.atraso-3{
	animation-delay:0.4s;
}

.atraso-4{
	animation-delay:0.6s;
}

lazy-image{
	background-color:transparent;
}

.slick-slider{

	img{
		display:inline-block !important;
	}

}

.carousel{
	text-align:center;

	.carousel-item{
		position: relative;
		z-index:200;
	}

}

:focus{
	outline:none !important;
}

.slick-slider{

	.slick-track{
		text-align:center;
		
		@include media-breakpoint-up(sm){
			display:flex;
			flex-wrap:wrap;
			align-items:flex-end;
		}

	}

	img{
		@include img-fluid();
	}

}

// FONTS
.font--italic{
	font-style:italic;
}

.font--fnt-11{
	font-size:11px;
}

.font--fnt-13{
	font-size:13px;
}

.font--fnt-14{
	font-size:14px;
}

.font--fnt-15{
	font-size:15px;
}

.font--fnt-16{
	font-size:16px;
}

.font--fnt-17{
	font-size:17px;
}

.font--fnt-18{
	font-size:18px;
}

.font--fnt-20{
	font-size:20px;
}

.font--fnt-23{
	font-size:23px;
}

.font--fnt-27{
	font-size:27px;
}

// concerta erro meu (João) de sprites em outros projetos
.icon{

	@each $icon,$value in $icons {
		&.#{$icon} {
			width: nth($value,3);
			height: nth($value,4);
			background-position: #{nth($value,1)} #{nth($value,2)};
		}
	}
}

.line--height-1{
	line-height:1;
}

// FONTS USADAS
.font--mnt-ex-bld{
	font-family: 'montserratextrabold';
}

.font--mnt-reg{
	font-family: 'montserratregular';
}

.font--mnt-blk{
	font-family: 'montserratblack';
}

.font--mnt-bld{
	font-family: 'montserratbold';
}

.title-radios{
	font-family: 'montserratbold';
	font-size:18px;
	margin-bottom:8px;	
}

.bar-menu{
	display:none;
	position:fixed;
	top:0;
	left:0;
	background-color:rgba(#000,0.5);
	width:100%;
	z-index:700;
	height:100%;
}

body{

	&.menu-active{

		.btn{

			&.btn--responsivo{

				.bars{
					transform:rotate(45deg);

					@include beforeAfter{
						transform:rotate(-90deg);
					}

					&:before{
						top:0;
					}

					&:after{
						bottom:0;
					}

				}

			}

		}

		.nav-content{
			left:0;
		}

	}

	&.menu-categorias{


		.menu-categorias-principal{
			left:0 !important;
		}

	}

}