.resumo-compra{
	color:#666;
	
	.resumo-compra-header{
		padding: 15px;
		color: #FFF;
		font-family: 'montserratbold';
		font-size: 16px;
		background-color:$theme;
	}

	.resumo-compra-corpo{
		padding: 13px 11px 11px 11px;
		background: #FFF;
		border-top:none;
	}

	.card-body,
	.table{
		background: #FFF;
	}

	.card{
		font-size:13px;
		border:none;
		border:2px solid #E2E2E2;
		border-top:none;

		.card-header{
			background-color:#EEEEEE;
			color:#333;
			font-family: 'montserratbold';
		}

		.card-body{
			padding:9px 13px;
		}

	}

	.card,
	.card > *{
		border-radius:0;
	}

	.card:last-child{
		border:none;
		background-color:#EEEEEE;
	}

	.table{
		margin-bottom:0;


		.total{
			font-size:16px;
			color:#333;
		}

		th{
			font-weight:normal;
		}

		th,
		td{
			border:none;
		}

		thead{
			background: #EEE;
			font-weight: 300;

			th ~ th{
				text-align: center;
			}
		}

		thead,
		tbody{

			tr{
				border-bottom:1px solid #CCC;
			}

			& > tr{
				td:not(:first-child){
					text-align: center;
				}
			}
		}

		tbody > tr > td:last-child,
		tfoot > tr > td:last-child{
			white-space: nowrap;
			text-align: right;
		}

		tbody > tr:hover > *{
			background: rgba(0,0,0,0.03);
		}

		tfoot{
			tr:nth-child(odd){
				td,
				th{
					background: transparent;
				}
			}
		}
	}

	.card-header{
		font-size: 16px;
		padding:6px 12px;
		background: transparent;
	}

	.tr-total,
	.card-header{
		border:1px solid #D6D6D6;
		background-color:#EEEEEE;
	}

}