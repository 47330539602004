.filtrar-categorias{
	border-radius:0;
	overflow:hidden;
	background-color:#EEEEEE;
	margin-bottom:30px;

	.header-filtro-categorias{
		padding: 13px 15px;
		text-align: center;
		color: #FFF;
		background: $theme;
		margin-bottom: 35px;
		width: 100%;
		font-size:19px;
		line-height:1.2;
		font-family: 'montserratblack';
	}

	h1,
	h2,
	h3{
		margin-bottom: 16px;
		color:$theme;
		font-family: 'montserratblack';
	}

	.filtro{
		margin-bottom: 30px;
		padding:5px 10px;

		&:last-child{

			&:after{
				display:none;
			}

		}

		ul{
			list-style: none;
			padding-left:0;
			margin-bottom: 0;
			font-size:14px;

			a{
				display: block;

				.badge{
					padding-left:0;
					color:#7D8083;
					font-size:14px;
					transition:color 0.6s linear;
				}

				&:hover{
					text-decoration: none;
					color: $theme;
				}
			}
		}

		a[aria-expanded="false"]{
			&.ocultar{
				display: none;
			}
		}

		a[aria-expanded="true"]{
			&.mostrar{
				display: none;
			}
		}

		.toggle-filtro{
			margin-top: 10px;
			font-size:14px;

			&:hover a{
				color:darken($theme,10%);
			}

		}
	}

	.radio-custom{

		.circle-legal{
			font-size:9px;
			border-color:#000;

			&:before{
				background-color:#000;
				border-color:#000;
			}

		}

		label{
			transition:color 0.6s linear;

			&:before{
				background-color:#000;
			}

		}

	}

	.options-filtro{
		max-height: 220px;
		overflow: auto;

		.form-group{
			margin-bottom: 0;
		}
	}

	@include media-breakpoint-only(md){
		display: flex;
		flex-wrap: wrap;

		.header-filtro-categorias{
			margin-bottom: 0;
		}

		.filtro{
			width: 33.333%;
			padding: 10px;
			border-left: 1px solid rgba(#000,0.2);
			border-bottom: 1px solid rgba(#000,0.2);
			padding-top: 30px;

			&:last-child{
				border-right: 1px solid rgba(#000,0.2);
			}
		}
	}
}