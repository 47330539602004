.btn{

	&.btn--pill{
		border-radius: 2rem;
	}

	&.btn--radius-6{
		border-radius:6px;
	}

	&.btn--width-174{
		min-width:174px;
	}

	&.btn--width-198{
		min-width:198px;
	}

	&.btn--width-214{
		min-width:214px;
	}

	&.btn--theme{
		@include button-variant($theme,$theme);
		color:#FFF;
	}

	&.btn--black{
		@include button-variant(#000,#000);
		color:#FFF;
	}

	&.btn--padrao{
		font-size:13px;
		border-radius:0;
		padding:12px 15px;
		font-family: 'montserratbold';
	}

	&.btn--padrao-2{
		font-size:13px;
		border-radius:6px;
		padding:12px 15px;
	}

	&.btn--padrao-3{
		font-size:15px;
		border-radius:6px;
		padding:12px 15px;
	}

	&.btn--padrao-4{
		font-size:12px;
		border-radius:0px;
		padding:8px 15px;
		font-family: 'montserratbold';
	}

	&.btn--finaliza-compra{
		box-shadow:0 2px #aeb3aa;
		@include button-variant(#6AC017, #6AC017);
		line-height:1;
		padding-left: 50px;
		min-width: 200px;
		text-align: left;
		position: relative;
		color:#FFF;
		font-size:13px;
		border-radius:5px;
		font-family: 'montserratbold';

		.subtitle{
			font-size:16px;
		}

		&:before,
		&:after{
			content:'';
			display: block;
			position: absolute;
		}

		&:before{
			width:20px;
			height: 10px;
			background: currentcolor;
			left:0.75rem;
			top:calc(50% - 5px);
		}

		&:after{
			border:10px solid transparent;
			border-left-color:#FFF;
			left: calc(0.75rem + 20px);
			top: calc(50% - 10px);
		}

		span{
			display: block;
			padding-left: 0.75rem;
			padding-bottom:0.35rem;
			padding-top:0.35rem;
			white-space:normal;

			&:before{
				content:'';
				height:100%;
				border-left: 3px solid rgba(darken(#6AC017,5%),0.2);
				position: absolute;
				top:0;
				left: 50px;
			}

		}

	}

	&.btn--responsivo{
		box-shadow:none;

		.bars{
			width:32px;
			height:5px;
			display:inline-block;
			position:relative;
			background-color:$theme;

			&,
			&:before,
			&:after{
				transition:all 0.6s linear;
			}

			@include beforeAfter{
				content:"";
				display:block;
				width:100%;
				height:100%;
				background-color:inherit;
				position:absolute;
				left:0;
			}

			&:before{
				top:calc(100% + 4px);
			}

			&:after{
				bottom:calc(100% + 4px);
			}

		}

	}

}

.dropdown-toggle{
	box-shadow:none !important;
}