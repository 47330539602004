.carousel,
.slick-slide{

	.carousel-indicators-custom{
		margin-bottom:9px;
		justify-content:flex-end;

		& > *{
			font-size:12px;
			width:1em;
			height:1em;
			border-radius:6px;
			background-color:#000;
			opacity:1;
			@include margin(0,9);
			border-radius:100%;
			transition:background-color 0.6s linear;

			&.active{
				background-color:$theme;
			}

		}

	}

}