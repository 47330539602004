.topo{

	.nav-content{

		@include media-breakpoint-up(lg){
			@include make-container();
			@include make-container-max-widths();
		}

		@include media-breakpoint-down(md){
			position:fixed;
			top:0;
			background-color:#FFF;
			left:-240px;
			width:240px;
			height:100%;
			overflow:auto;
			z-index:1000;
			transition:left 0.6s linear;
			border-right:1px solid $theme;

			.bar-dados__figure{
				padding:30px;
				text-align:center;
				margin-bottom:0;
				text-align:center;
			}

		}

		.bar-dados{

			@include media-breakpoint-up(lg){
				display:flex;
				flex-wrap:wrap;
				align-items:center;
				line-height:1;
			}

			.bar-dados__content{

				@include media-breakpoint-up(lg){
					padding-left:26px;
					flex-grow:1;
				}

			}

			.dados-topo{
				margin-bottom:24px;

				@include media-breakpoint-up(lg){
					border-bottom:1px solid #DDDDDD;
				}

				@include media-breakpoint-up(lg){
					display:flex;
					flex-wrap:wrap;
					align-items:center;
					max-width:908px;
					margin-left:auto;
					width:100%;
				}

				& > *{
					position:relative;
					min-height:54px;

					@include media-breakpoint-up(lg){
						@include margin(0,15);
						align-items:center;
						display:flex;
					}

					& + *{

						&:before{
							content:"";
							display:block;
							position:absolute;
							left:-22px;
							top:50%;
							width:1px;
							height:24px;
							background-color:#DDDDDD;
							margin:0 8px;
							transform:translateY(-50%);
						}

					}

				}

				.carrinho-topo{

					@include media-breakpoint-up(lg){
						max-width:120px;
					}

				}

				.bem-vindo{
					flex-grow:1;

					@include media-breakpoint-down(md){
						padding:10px 15px;
					}

					a{
						color:$theme;
						font-family: 'montserratbold';
					}

				}

				.carrinho-topo{

					@include media-breakpoint-down(md){
						display:none;
					}

				}

				.carrinho-topo{

					.valor{
						color:$theme;
						font-family: 'montserratbold';
					}

				}

			}

		}

		.dados-form{

			@include media-breakpoint-up(lg){
				display:flex;
				flex-wrap:wrap;
				align-items:center;
				max-width:908px;
				margin-left:auto;
				width:100%;
				padding-bottom:6px;
			}

			.form-buscas{
				max-width:444px;
				width:100%;

				@include media-breakpoint-only(lg){
					max-width:320px;
				}

			}

			.dados-form__info{

				.dropdown{

					.dropdown-toggle{

						&:after{
							display:none;
						}

					}

					.dropdown-menu{
						transform:none !important;
						top:100% !important;
						padding:15px 20px;
						font-size:14px;

						li{
							margin-bottom:10px;
							color:#000;
							font-family: 'montserratbold';

							&:hover{
								color:$theme;
							}

							&:last-child{
								margin-bottom:0;
							}

						}

					}

				}

				@include media-breakpoint-down(md){
					text-align:center;
				}

				@include media-breakpoint-up(lg){
					display:flex;
					flex-wrap:wrap;
					align-items:center;
					max-width:435px;
					flex-grow:1;
				}

				.media-custom{

					@include media-breakpoint-down(md){
						margin-bottom:20px;
					}

					.media-custom__icon{

						@include media-breakpoint-down(md){
							margin-bottom:8px;
						}

					}

				}

				.media-custom-telefones,
				.media-custom-whatsapp{
					font-family: 'montserratbold';
				}

				.media-custom-telefones{
					font-size:12px;
					color:#000;
					padding:6px 12px;

					@include media-breakpoint-up(lg){
						border:1px solid #ccc;
					}

					.media-custom__text{
						line-height:1.2;
					}

				}

				.media-custom-whatsapp{

					span{
						text-decoration:underline;
					}

				}

				.media-custom-links{
					flex-grow:1;
					justify-content:flex-end;

					@include media-breakpoint-up(lg){
						padding-left:11px;
					}

					a{
						display:block;
						margin-bottom:3px;

						&:hover{
							text-decoration:underline;
						}

					}

					.media-custom__text{
						position:relative;

						&:before{
							content:"";
							display:block;
							position:absolute;
							top:0;
							left:-11px;
							width:1px;
							height:100%;
							background-color:#CCC;
						}

					}

				}

				& > *{

					@include media-breakpoint-up(lg){
						margin-left:11px;
					}

				}

			}

		}

		.bar-principal{
			background-color:$theme;
			color:#FFF;
			position:relative;

			@include media-breakpoint-up(lg){
				display:flex;
				flex-wrap:wrap;
				align-items:center;
				justify-content:space-between;

				@include beforeAfter{
					content:"";
					display:block;
					width:100vw;
					height:100%;
					background-color:inherit;
					position:absolute;
					top:0;
					z-index:-1;
				}

				&:before{
					left:100%;
				}

				&:after{
					right:100%;
				}

			}

			.categorias-topo{
				max-width:246px;
				flex-grow:1;

				@include media-breakpoint-up(lg){
					margin-right:26px;
				}

				& > .btn{
					background-color:darken($color: #0af9ef, $amount: 15%) ;
					color:#FFF;
					font-size:13px;
					padding:0;
					text-align:center;
					height:100%;
					font-family: 'montserratbold';
					padding:17px 15px;
					border-radius:0;

					.icon{
						margin-left:9px;
						vertical-align:baseline;
					}

				}

			}

			.menu-categorias-topo{
				flex-grow:1;
				font-size:13px;
				font-family: 'montserratbold';
				margin-right:auto;

				@include media-breakpoint-up(lg){
					max-width:840px;
					display:flex;
					flex-wrap:wrap;
				}

				& > li{
					flex-grow:1;

					& > a{
						display:block;

						@include media-breakpoint-up(lg){
							padding:18px 0px;
						}

					}

					&.active > a,
					&:hover  > a{

						@include media-breakpoint-up(lg){
							color:#000;
						}

					}

				}

			}

		}

	}

	&.fx{

		.mbl-controls{
			position:fixed;
			top:0;
			left:0;
			width:100%;
			box-shadow:0 0 8px rgba(#000,0.5);
			z-index:600;
			animation:fadeInDown 0.6s linear;
		}

	}

}
